.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 1200px;
  margin-top: 40px;
}

.header {
  color: var(--black-default, #222);
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: Wix Madefor Display;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.32px;
  margin-bottom: 38px;

}

.PreviewBlock {
  display: flex;
  flex-wrap: wrap;
  gap: 31px;
  margin-bottom: 120px;
}

.PreviewTabletBlock {
  display: none;
}

.readAboutUs {
  border-radius: 32px;
  background: var(--Brand-Primary-Normal, #0076DE);
  padding: 8px 16px;
  color: var(--White-Normal, #FFF);
  font-family: Wix Madefor Display;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &:hover {
    background: var(--Brand-Primary-Hover, #268AE3);
  }

  &:active {
    background: var(--Brand-Primary-Hover-pressing, #0165BE);
  }
}

@media screen and (min-width: 320px) and (max-width: 742px) {
  .container {
    width: calc(100vw - 32px);
    margin: 0;
    margin-top: 28px;
  }

  .header {
    color: var(--black-default, #222);

    font-family: Wix Madefor Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.24px;
    margin-bottom: 28px;
  }

  .PreviewBlock {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 743px) and (max-width: 1279px) {
  .container {
    width: calc(100vw - 48px);
    margin-top: 40px;
  }

  .header {
    color: var(--black-default, #222);

    font-family: Wix Madefor Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.24px;
    margin-bottom: 28px;
  }

  .PreviewBlock {
    width: calc(100vw - 40px);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    margin-bottom: 80px;
    overflow: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
      
    }

    .PreviewTabletBlock {
      width: calc(100vw - 40px);
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 20px;
      margin-bottom: 80px;
      overflow: scroll;

    }

    .swiper {
      display: flex;
      gap: 20px;
    }
  }}