.container {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 428px;

  position: relative;
  background: linear-gradient(0deg, rgba(1, 53, 125, 0.16) 0%, rgba(2, 110, 209, 0.11) 100%), conic-gradient(from 270deg at 76.68% 100%, #0076DE 0deg, rgba(0, 118, 222, 0.90) 360deg, rgba(0, 118, 222, 0.90) 360deg);
}

.content {
  background-image: url("../../../assets/Banner.svg");
  background-repeat: no-repeat;
  background-position: bottom right -10px;

  width: 1200px;
  height: 428px;
}

.header {
  margin-top: 40px;
  margin-left: 205px;
  color: var(--White-Normal, #FFF);

  /* Desktop/H1 */
  font-family: Wix Madefor Display;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 38.4px */

}

.text {
  margin-left: 205px;
  margin-top: 28px;
  width: 388px;

  color: var(--White-Normal, #FFF);

  /* General/Regular */
  font-family: Wix Madefor Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  /* 20.8px */
  letter-spacing: 0.16px;
}

.bgTablet {
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 742px) {
  .content {
    background-image: url("../../../assets/BackgroundMob.png");
    background-repeat: no-repeat;
    background-position: bottom center;
    width: 100vw;

    background: linear-gradient(0deg, rgba(1, 53, 125, 0.16) 0%, rgba(2, 110, 209, 0.11) 100%), conic-gradient(from 270deg at 76.68% 100%, #0076DE 0deg, rgba(0, 118, 222, 0.90) 360deg, rgba(0, 118, 222, 0.90) 360deg) url("../../../assets/BackgroundMob.png");
    height: 346px;
  }

  .header {
    margin-top: 20px;
    padding-left: 16px;
    margin-left: 0;
    color: var(--White-Normal, #FFF);

    /* Compact/H1 */
    font-family: Wix Madefor Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 28.8px */

  }

  .text {
    padding-left: 16px;
    margin-left: 0;
    margin-top: 24px;
    padding-right: 6vw;
    width: 100vw;

    color: var(--White-Normal, #FFF);

    /* General/Regular */
    font-family: Wix Madefor Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    /* 20.8px */
    letter-spacing: 0.16px;
  }

  .container {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 346px;


    position: relative;
    background-color: #0076DE;
  }
}



@media screen and (min-width: 743px) and (max-width: 1279px) {
  .container {
    display: flex;
    height: 350px;
    width: 100vw;
    margin-top: 0px;
    padding-top: 40px;
    background-image: none;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 0;
    position: relative;
    background: linear-gradient(0deg, rgba(1, 53, 125, 0.16) 0%, rgba(2, 110, 209, 0.11) 100%), conic-gradient(from 270deg at 76.68% 100%, #0076DE 0deg, rgba(0, 118, 222, 0.90) 360deg, rgba(0, 118, 222, 0.90) 360deg)


  }

  .content {
    background-image: none;
    z-index: 3;
  }

  .img {
    width: 680px;
    height: 294px;
    margin-right: calc(50px + (100vw - 1024px) * (50 / (1279 - 1024)));

  }

  .header {
    margin-top: 40px;
    margin-left: 24px;
    color: var(--White-Normal, #FFF);

    /* Desktop/H1 */
    font-family: "Wix Madefor Display";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 38.4px */
    /* 38.4px */

  }

  .text {
    margin-left: 24px;
    margin-top: 28px;
    width: 344px;

    color: var(--White-Normal, #FFF);
    z-index: 2;

    /* General/Regular */
    font-family: Wix Madefor Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    /* 20.8px */
    letter-spacing: 0.16px;
  }

  .bgTablet {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}