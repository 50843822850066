.container {
    color: var(--color-components-text-contrast, #FFF);
    width: 158px;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    padding: 8px 12px;
    border-radius: var(--padding-s, 8px);
    background: var(--color-components-background-neutral, #070707);
    position: relative;
    

    &::after {
        content: '';
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        border-width: 0 6px 8px 6px;
        border-style: solid;
        border-color: transparent transparent #070707 transparent;
    }
}




@media screen and (min-width: 320px) and (max-width: 742px) {
    .container {
        color: var(--color-components-text-contrast, #FFF);
        width: 158px;
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        padding: 8px 12px;
        border-radius: var(--padding-s, 8px);
        background: var(--color-components-background-neutral, #070707);
        position: relative;
        
    
        &::after {
            content: '';
            position: absolute;
            top: -8px;
            left: 50%;
            transform: translateX(-50%);
            border-width: 0 6px 8px 6px;
            border-style: solid;
            border-color: transparent transparent #070707 transparent;
        }
    }
}