body {
  margin: 0;
  font-family: Wix Madefor Display;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;

}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overflow: overlay;
  background: white;
  color: #222;
  font-family: "Wix Madefor Display";
  overflow-y: scroll;

}

a {
  text-decoration: none;
  cursor: pointer;
  -webkit-user-drag: none;
}

button {
  border: none;
  cursor: pointer;
  background: none;
  color: #222;
}

button:disabled {
  cursor: initial;
}

img {
  display: block;
}

input,
textarea {
  border: none;
  outline: none;
}

input::placeholder {
  font-family: "Wix Madefor Display";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #9da1ae;
}