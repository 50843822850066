.container {
    width: 1200px;
    display: flex;
    margin: 0 auto;

}

.content {
    margin-top: 40px;
}

.header {
    color: var(--Black-Normal, #070707);

    /* Desktop/H1 */
    font-family: Wix Madefor Display;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 38.4px */
}

.buttonsBlock {
    display: flex;
    gap: 16px;
    /*     margin-top: 24px;
 */
}

.button {
    font-family: Wix Madefor Display;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 32px;
    display: flex;
    height: 38px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--Brand-Primary-Normal, #0076DE);
    background-color: #fff;
    color: #0076DE;
    cursor: pointer;

    &:hover {
        background: #D9EBFA;

    }

    &:active {
        background: var(--Brand-Secondary-Pressing, #C4DFF7);


    }



    &.active {
        background: var(--Brand-Primary-Normal, #0076DE);
        color: var(--White-Normal, #FFF);

        &:hover {
            background: var(--Brand-Primary-Hover, #268AE3);

        }

        &:active {
            background: var(--Brand-Primary-Hover-pressing, #0165BE);


        }


    }
}

.postTableBlock {
    display: none;
}

@media screen and (min-width: 320px) and (max-width: 742px) {
    .container {
        width: calc(100vw - 32px);
        display: flex;
        margin: 0 16px;
    }

    .content {
        margin-top: 20px;
    }

    .buttonsBlock {
        display: flex;
        gap: 0px;
        width: calc(100vw - 16px);

        /*         margin-top: 20px;
 */
        overflow: scroll;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;

        }
    }

    .button {
        padding: 8px 16px;
        margin-right: 16px;

        &:hover {
            background-color: #fff;
            color: #0076DE;
        }
        &:active{
            background: var(--Brand-Secondary-Pressing, #C4DFF7);
          }
        &.active{
            &:hover{
                background: var(--Brand-Primary-Normal, #0076DE);
                color: var(--White-Normal, #FFF);
            }
        }
    }
}

@media screen and (min-width: 743px) and (max-width: 1279px) {
    .container {
        width: calc(100vw - 48px);
        display: flex;
        margin: 0 24px;
    }

    .content {
        margin-top: 28px;
    }

    .postTableBlock {
        display: block;
        margin-top: 32px;
    }

    .postBlock {
        display: none;
    }
    .button {

        &:hover {
            background-color: #fff;
            color: #0076DE;
        }
        &:active{
            background: var(--Brand-Secondary-Pressing, #C4DFF7);
          }
        &.active{
            &:hover{
                background: var(--Brand-Primary-Normal, #0076DE);
                color: var(--White-Normal, #FFF);
            }
        }
    }
}