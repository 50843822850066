.container {
  display: flex;
  flex-direction: column;

}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.text {
  color: var(--black-default, #222);

  /* PC/Text-content/H2 */
  font-family: Wix Madefor Display;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 32px;
}

@media screen and (min-width: 320px) and (max-width: 742px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 80px;
  }

  .text {
    color: var(--black-default, #222);

    /* Mobile/H1 */
    font-family: Wix Madefor Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 28.8px */
    letter-spacing: 0.24px;
    /*     padding-bottom: 8px;
 */
    margin-bottom: 0;
  }

  .content {
    gap: 20px;
  }
}

@media screen and (min-width: 743px) and (max-width: 1279px) {
  .container {
    display: flex;
    flex-direction: column;
    margin: 0;
    width: calc(100vw - 40px);
    margin-bottom: 80px;
  }

  .content {
    gap: 20px;
    flex-direction: row;
    width: calc(100vw - 40px);
    flex-wrap: wrap;

  }

  .text {
    color: var(--black-default, #222);

    /* PC/Text-content/H2 */
    font-family: Wix Madefor Display;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 28px;
  }
}