.header {
  margin-top: 24px;
  color: var(--black-default, #222);

  /* PC/Text-content/H2 */
  font-family: Wix Madefor Display;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cloudBlock {
  margin-top: 24px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  max-width: 687px;
  margin-bottom: 120px;
}

.cloud {
  display: flex;
  padding: var(--component-component-xxs, 8px) var(--component-component-m, 16px);
  justify-content: center;
  align-items: center;
  color: var(--brand-default, #0076de);

  /* PC/Header/Button */
  color: var(--Brand-Primary-Normal, #0076DE);

  /* General/Button */
  font-family: Wix Madefor Display;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 12px;
  border: 1px solid var(--Brand-Primary-Normal, #0076DE);

  &:hover {
    background: var(--Brand-Secondary-Hover, #D9EBFA);
  }

  &:active {
    background: var(--Brand-Secondary-Pressing, #C4DFF7);

  }
}

@media screen and (min-width: 320px) and (max-width: 742px) {
  .header {
    margin-top: 28px;
    color: var(--black-default, #222);

    /* Mobile/H2 */
    font-family: Wix Madefor Display;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 26.4px */
    letter-spacing: 0.22px;
    width: calc(100vw - 32px);
  }

  .cloudBlock {
    margin-top: 24px;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    max-width: calc(100vw - 32px);
    margin-bottom: 40px;
  }

  .cloud {
    display: flex;
    padding: var(--component-component-xxs, 8px) var(--component-component-m, 16px);
    justify-content: center;
    align-items: center;
    color: var(--brand-default, #0076de);

    /* PC/Header/Button */
    color: var(--Brand-Primary-Normal, #0076DE);

    /* General/Button */
    font-family: "Wix Madefor Display";
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 12px;
    border: 1px solid var(--Brand-Primary-Normal, #0076DE);

    &:hover {
      background: var(--brand-background, #fff);
    }
    &:active{
      background: var(--Brand-Secondary-Pressing, #C4DFF7);
    }
  }
}

@media screen and (min-width: 743px) and (max-width: 1279px) {
  .header {
    margin-top: 16px;
    color: var(--black-default, #222);

    /* Mobile/H2 */
    font-family: Wix Madefor Display;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 26.4px */
    letter-spacing: 0.22px;
    width: calc(100vw - 40px);
  }

  .cloudBlock {
    margin-top: 24px;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    max-width: calc(100vw - 40px);
    margin-bottom: 40px;
  }

  .cloud {
    display: flex;
    padding: var(--component-component-xxs, 8px) var(--component-component-m, 16px);
    justify-content: center;
    align-items: center;
    color: var(--brand-default, #0076de);

    /* PC/Header/Button */
    /* General/Button */
    font-family: "Wix Madefor Display";
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 12px;
    border: 1px solid var(--Brand-Primary-Normal, #0076DE);


    &:hover {
      background: var(--brand-background, #fff);
    }
    &:active{
      background: var(--Brand-Secondary-Pressing, #C4DFF7);
    }
  }
}