.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  /*   width: 1240px;
 */
}

.header {

  color: var(--black-default, #222);
  display: flex;
  margin: 0 auto;
  width: 1200px;
  gap: 16px;
  font-family: Wix Madefor Display;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.32px;

}

.readAboutUs {
  border-radius: 32px;
  background: var(--Brand-Primary-Normal, #0076DE);
  padding: 8px 16px;
  color: var(--White-Normal, #FFF);

  /* General/Button */
  font-family: Wix Madefor Display;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &:hover {
    background: var(--Brand-Primary-Hover, #268AE3);
  }

  &:active {
    background: var(--Brand-Primary-Hover-pressing, #0165BE);
  }
}

.aboutUs {
  width: 1200px;
  display: flex;
  margin: 0 auto;
  margin-top: 40px;
}

@media screen and (min-width: 320px) and (max-width: 742px) {
  .container {
    display: flex;
    flex-direction: column;

    margin: 0;
    width: calc(100vw - 32px);

  }

  .aboutUs {
    width: calc(100vw - 32px);
    display: flex;
    margin: 0 auto;
    margin-top: 32px;
  }

  .header {


    display: flex;
    /*   justify-content: center;
   */
    align-items: center;
    padding-left: 16px;
    margin: 0 auto;
    width: calc(100vw - 32px);
    gap: 16px;
    color: var(--Black-Normal, #070707);

    /* Compact/H2 */
    font-family: Wix Madefor Display;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 26.4px */

  }

  .previeBlock {
    margin-left: 16px;
  }

  .readAboutUs {
    &:hover {

      background: var(--Brand-Primary-Normal, #0076DE);
     
      color: var(--White-Normal, #FFF);
    }
    &:active{
      background: var(--Brand-Primary-Hover-pressing, #0165BE);
    }
  }
}



@media screen and (min-width: 743px) and (max-width: 1279px) {

  .container {
    display: flex;
    flex-direction: column;
    /*     margin: 0 20px;
 */
    width: 100vw;
  }

  .aboutUs {
    width: calc(100vw - 48px);
    display: flex;
    margin: 0 auto;
    margin-top: 40px;
  }
}