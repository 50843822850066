.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  width: 379px;
  opacity: 1;
  cursor: pointer;
  color: #0076DE;

  &:hover {
    opacity: 0.85;
  }

  & .imageContainer {
    max-width: 379px;
    height: 259px;
    margin-bottom: 18px;

    & .image {
      width: 100%;
      height: 259px;
    }


  }

  & .title {
    color: var(--Brand-Primary-Normal, #0076DE);

    /* Desktop/H3 */
    font-family: Wix Madefor Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  & .date {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--Black-Normal, #070707);

    /* General/Mail */
    font-family: Wix Madefor Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 14px;
  }

  & .text {

    color: var(--Brand-Primary-Normal, #0076DE);

    /* Desktop/H3 */
    font-family: Wix Madefor Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 28.8px */
  }
}

@media screen and (min-width: 320px) and (max-width: 742px) {
  .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    width: calc(100vw - 32px);
    &:hover {
      opacity: 1;
    }

    & .imageContainer {
      position: relative;
      max-width: calc(100vw - 32px);
      margin-bottom: 16px;
      margin-right: 16px;
      height: auto;

      & .image {
        width: calc(100vw - 32px);
        height: auto;
      }

      & .dateOverlay {
        position: absolute;
        bottom: 0px;
        right: 0px;
        background-color: white;
        color: var(--brand-default, #0076de);
        padding: 4px 15px;
        border-top-left-radius: 4px;
        font-family: Wix Madefor Display;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }
    }
    & .date{
      margin-bottom: 12px;
    }
    & .title {

      font-family: Wix Madefor Display;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      /* 28.8px */
      white-space: pre-line;

    }

    & .text {
      margin-top: 12px;
      color: var(--black-secondary-default, #6b6b6b);

      font-family: Wix Madefor Display;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      /* 19.5px */
      white-space: pre-line;
    }
  }
}

@media screen and (min-width: 743px) and (max-width: 1279px) {


  .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    max-width: 379px;
    &:hover {
      opacity: 1;
    }

    &.sidebar {
      max-width: calc((100vw - 24px) / 2 - 20px);
      width: calc((100vw - 24px) / 2 - 20px);
    }

    & .imageContainer {
      position: relative;
      max-width: auto;
      margin-bottom: 16px;
      margin-right: 0px;
      height: auto;

      & .image {
        width: 379px;

        &.sidebar {
          max-width: calc((100vw - 24px) / 2 - 20px);
          width: calc((100vw - 24px) / 2 - 20px);
          height: auto;
        }
      }
    }

    & .title {
      color: var(--Brand-Primary-Normal, #0076DE);
      
      font-family: "Wix Madefor Display";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
    }
  }
}
