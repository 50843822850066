.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 1200px;

}

.contentBlock {
  display: flex;
  padding-top: 40px;
  justify-content: space-between;
}

.imageBlockMain {
  max-width: 813px;
  height: 425px;
  overflow: hidden;
}

.imageBlock {
  max-width: 813px;
  height: auto;
  padding-top: 8px;
}

.date {

  /* PC/Article/Date */
  color: var(--Black-Normal, #070707);

  /* General/Mail */
  font-family: Wix Madefor Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.h1 {
  margin-top: 12px;
  margin-bottom: 28px;
  color: var(--Brand-Primary-Normal, #0076DE);

  /* Desktop/H1 */
  font-family: Wix Madefor Display;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 38.4px */
  width: 680px;

}

.text {
  max-width: 708px;
  color: var(--black-default, #222);

  color: var(--Black-Normal, #070707);

  /* Desktop/Post */
  font-family: "Wix Madefor Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 28px */
  letter-spacing: 0.2px;
  padding-bottom: 16px;
}

.h2 {
  color: var(--brand-default, #0076de);

  /* PC/Text-content/H2 */
  font-family: Wix Madefor Display;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 33.6px */
  margin-top: 24px;
  margin-bottom: 20px;
}

.img {
  width: 789px;
  height: 439px;
  object-fit: cover;
}

@media screen and (min-width: 320px) and (max-width: 742px) {
  .container {
    display: flex;
    flex-direction: column;
    margin: 0 16px;
    width: calc(100vw - 32px);
  }

  .contentBlock {
    display: flex;
    flex-direction: column;
    padding-top: 19px;
    justify-content: space-between;
  }

  .imageBlockMain {
    max-width: calc(100vw - 32px);
    height: auto;
    border-radius: 0px;
  }

  .imageBlock {
    max-width: calc(100vw - 32px);
    height: auto;
    border-radius: 0px;
    padding-top: 4px;
  }

  .img {
    max-width: calc(100vw - 32px);
    height: auto;
    border-radius: 0px;
    object-fit: cover;
    /*     content:url("../../assets/kydas-team-winPre.webp")
 */
  }

  .date {
    color: var(--Black-Normal, #070707);

    /* General/Mail */
    font-family: "Wix Madefor Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 20px;

  }

  .h1 {
    margin-top: 8px;
    margin-bottom: 24px;
    width: calc(100vw - 32px);

    color: var(--brand-default, #0076de);

    /* Mobile/H1 */
    font-family: Wix Madefor Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 28.8px */
    letter-spacing: 0.24px;

  }

  .text {
    max-width: calc(100vw - 32px);
    color: var(--black-default, #222);

    /* Mobile/Regular */
    font-family: Wix Madefor Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.16px;
    padding-bottom: 12px;

  }

  .h2 {
    color: var(--brand-default, #0076de);

    /* Mobile/H2 */
    font-family: Wix Madefor Display;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 26.4px */
    letter-spacing: 0.22px;
    margin-top: 24px;
    margin-bottom: 16px;
  }
}

@media screen and (min-width: 743px) and (max-width: 1279px) {
  .container {
    display: flex;
    flex-direction: column;
    margin: 0 24px;
    width: calc(100vw - 48px);
  }
  .imageBlockMain {
    max-width: calc(100vw - 48px);
    height: auto;
  }
  .contentBlock {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    justify-content: space-between;
  }

  .img {
    max-width: calc(100vw - 40px);
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }

  .date {
    color: var(--Black-Normal, #070707);

    /* General/Mail */
    font-family: "Wix Madefor Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 24px;

  }

  .h1 {
    margin-top: 14px;
    margin-bottom: 28px;

    color: var(--Brand-Primary-Normal, #0076DE);

    /* Desktop/H1 */
    font-family: "Wix Madefor Display";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 38.4px */

  }

  .text {
    max-width: calc(100vw - 40px);
    color: var(--Black-Normal, #070707);

    /* Desktop/Post */
    font-family: "Wix Madefor Display";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 28px */
    letter-spacing: 0.2px;
    padding-bottom: 16px;

  }

  .h2 {
    color: var(--Brand-Primary-Normal, #0076DE);

    /* Desktop/H2 */
    font-family: "Wix Madefor Display";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 33.6px */
    margin-top: 24px;
    margin-bottom: 20px;
  }
}