.container {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 1200px;
  margin-top: 20px;
  padding-bottom: 20px;

}

.logo {
  opacity: 1;
  z-index: 100000;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #0076DE;

}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-left: 371px;
}

.navbar-item {
  color: #FFFFFF;
  font-family: Wix Madefor Display;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.03em;
  text-align: left;
  border: 1px solid var(--White-Normal, #0076DE);
  border-radius: 32px;

  padding: 8px 14px;

  cursor: pointer;

  &:hover {
    border-radius: 32px;
    background: var(--Brand-Primary-Hover-pressing, #0165BE);
  }

  &.active {
    border-radius: 32px;
    border: 1px solid var(--White-Normal, #FFF);
    padding: 8px 14px;

    &:hover {
      background: none;
    }
  }
}

.emailBlock {
  align-items: center;
  display: flex;
  gap: 17px;
  margin-left: auto;

}

.emailBlick {
  position: relative;
  overflow: visible;
}

.popOver {
  position: absolute;
  top: 35px;
  z-index: 100000;
  cursor: pointer;
}

.email {
  color: var(--White-Normal, #FFF);
  /* General/Mail */
  font-family: Wix Madefor Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 1;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8
  }

  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

}

.emailButton {
  color: var(--Brand-Primary-Normal, #0076DE);

  /* General/Button */
  font-family: Wix Madefor Display;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 32px;
  background: var(--White-Normal, #FFF);
  padding: 12px 22px;
  opacity: 1;

  &:hover {
    background: var(--Brand-Secondary-Hover, #D9EBFA);

  }

  &:active {
    background: var(--Brand-Secondary-Pressing, #C4DFF7);

  }
}

.burgerContainer {
  display: none;
}

.burger {
  display: none;
}

.tabletNav {
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 742px) {
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    margin-top: 0;
    width: calc(100vw - 32px);
    padding-top: 20px;
    position: relative;
    z-index: 1000000;

  }

  .navbar {
    display: none;
  }

  .content {
    align-items: center;
    /*   background-color: blue;  */
    z-index: 1000000;
    margin-top: 0;
    position: relative;

  }

  .navbar-item {
    color: var(--White-Normal, #FFF);

    /* Compact/Menu */
    font-family: Wix Madefor Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.72px;
    opacity: 0.7;
    border: none;
    padding: 0;

    &:hover {
      background: none;
    }

    &.active {
      border: none;
      opacity: 1;
      padding: 0;


    }
  }

  .emailBlock {
    display: none;
  }

  .burgerMenuContent {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100vw;
    height: calc(100% - 77px);
    background-color: #0076DE;
    z-index: 999;
    /* padding-top: 5px; */
    top: 20;
    left: 0;
    transition: transform 0.25s ease-out;
    animation: enter 0.25s ease-out;

    @keyframes enter {
      from {
        transform: translate(0, calc(-100% + 44px));
      }

      to {
        transform: translate(0, 0);
      }
    }

    .emailBlock {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 15px;
      /* margin-bottom: 20px; */
      margin-left: 0;
      margin-top: auto;

      .email {
        color: var(--White-Normal, #FFF);

        /* General/Mail */
        font-family: Wix Madefor Display;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 8px;
        &:hover{
          color: var(--White-Normal, #FFF);

        }
      }

      .emailButton {

        display: flex;
        margin: 0 16px;
        width: calc(100% - 32px);
        align-items: center;
        justify-content: center;
        color: var(--Brand-Primary-Normal, #0076DE);

        /* General/Button */
        font-family: Wix Madefor Display;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border-radius: 32px;
        background: var(--White-Normal, #FFF);
        padding: 8px 16px;
        height: 50px;


        margin-bottom: 40px;

        &:hover {/* 
          background: var(--White-Normal, #FFF);
          color: var(--Brand-Primary-Normal, #0076DE); */


        }
        &:active{
          background: var(--Brand-Secondary-Pressing, #C4DFF7);
        }
      }
    }
    @media (hover: hover) {
      .button:hover { }
      }
  }

  .menuExiting {
    transform: translate(0, -100%);
    animation: exit 0.25s ease-out;

    @keyframes exit {
      from {
        transform: translate(0, 0);
      }

      to {
        transform: translate(0, -100%);
      }
    }
  }

  .burgerMenu {
    margin-left: 16px;
    margin-top: 21px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .burgerContainer {
    display: block;
    align-items: center;
    z-index: 10000;
  }

  .burger {
    position: absolute;
    top: 20;
    right: 0;
    display: block;
    z-index: 100000;
  }

  .burgerButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 84px;
    height: 38px;
/*     margin-right: 16px;
 */    font-family: "Wix Madefor Display";
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 32px;
    background: var(--White-Normal, #FFF);
    color: #0076DE;
    &:active{
      background: var(--Brand-Secondary-Pressing, #C4DFF7);
    }
  }
}

@media screen and (min-width: 743px) and (max-width: 1279px) {
  .tabletNav {
    display: flex;

    gap: 20px;
    margin-left: 24px;
    margin-bottom: 20px;
  }

  .navbar {
    display: none;
  }

  .navbar-item {
    font-family: Wix Madefor Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    &:hover {
      background: none;
    }
    
    &.active {
      opacity: 1;
    }
  }

  .container {
    margin: 0 24px;
    width: calc(100vw - 48px);
    margin-top: 20px;
  }

  .emailButton {
    padding: 8px 16px;
    color: var(--Brand-Primary-Normal, #0076DE);

    /* General/Button */
    font-family: "Wix Madefor Display";
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}